import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Navbar from '../navigation/Navbar';
import Footer from '../Footer';
import FixedBox from '../FixedBox';
import ContactForm from '../Forms/ContactForm';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { ToastContainer } from 'react-toastify';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import { selectAboutUs } from '@a1-ict/fsbo/generalSlice/selectors';

const Layout = ({ children, navColor = '', margined = false, showMenu = true }) => {
  const [footerHeight, setFooterHeight] = useState(false)
  const rightDrawer = useDialog('rightDrawer');
  const leftDrawer = useDialog('leftDrawer');
  const contactForm = useDialog('contactForm');

  const breakpoints = useBreakpoint();
  const aboutUs = useSelector(selectAboutUs);

  function updateSize() {
    const footer = document.getElementById("footer");
    setFooterHeight(footer.clientHeight);
  }

  useEffect(() => {
    if (aboutUs) updateSize();
  }, [aboutUs])

  useEffect(() => {
    if (window) window.addEventListener('resize', updateSize);
    updateSize();
    return () => { if (window) window.removeEventListener('resize', updateSize) };
  }, []);



  return (
    <>
      <ContactForm />
      <Navbar bg={navColor} marginedContent={margined} showMenu={showMenu}></Navbar>
      <div
        style={{ marginBottom: !breakpoints.xl && footerHeight ? footerHeight : 0 }}
        className={`content ${margined ? ' margined ' : ' '} ${leftDrawer.isOpen ? ' moveToRight' : rightDrawer.isOpen ? ' moveToLeft' : ' '
          }`}
      >
        {children}
      </div>
      <Footer />
      <FixedBox openModal={contactForm.toggle}></FixedBox>
      <ToastContainer />
    </>
  );
};

export default Layout;
