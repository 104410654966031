import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'reactstrap';

import { FaBuilding } from '@react-icons/all-files/fa/FaBuilding';
import { FaPhone } from '@react-icons/all-files/fa/FaPhone';
import { FiMail } from '@react-icons/all-files/fi/FiMail';

import GooglePlay from '../images/googleplay.svg';
import ApplePay from '../images/applepay.svg';
import Social1 from '../images/social1.svg';

import Social4 from '../images/social4.svg';
import Youtube from '../images/youtube.svg';
import { fetchAboutUs, fetchFsboContacts } from '@a1-ict/fsbo/generalSlice/requests';
import { selectAboutUs, selectFsboContacts } from '@a1-ict/fsbo/generalSlice/selectors';

import { ViberIcon, WhatsappIcon } from 'react-share';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

const fontAwesome = {
  FaBuilding: <FaBuilding />,
  FaPhone: <FaPhone />,
  email_address: <FiMail size={18} color="#e9a081" />,
  viber_number: (
    <span className="d-flex">
      <ViberIcon round size={18} />
      <WhatsappIcon round size={18} />
    </span>
  ),
  whats_app_number: <WhatsappIcon round size={18} />,
};

const Footer = () => {
  const categori = [
    { name: 'Условия за ползване', path: '/terms/common' },
    { name: 'Политика за поверителност', path: '/terms/privacy' },
    { name: 'Политика за бисквитките ', path: '/terms/cookies' },
    // { name: 'Карта на сайта', path: '/' },
    { name: 'Въпроси и предложения към Имот.com', path: '#', func: () => contactForm.toggle() },
    // { name: 'Реклама', path: '/' },
    // { name: 'Полезно', path: '/' },
    // { name: 'Услуги', path: '/' },
  ];

  const dispatch = useDispatch();
  const aboutUs = useSelector(selectAboutUs);
  const fsboContacts = useSelector(selectFsboContacts);
  const contactForm = useDialog('contactForm');

  useEffect(() => {
    dispatch(fetchAboutUs());
    dispatch(fetchFsboContacts());
  }, []);

  const contacts = Object.keys(fsboContacts).map((key, index) => {
    let icon = fontAwesome[key];

    if (key === 'email_address')
      return (
        <li key={index} className=" d-flex">
          <div className="mr-2">{icon}</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (window)
                window.open(
                  `mailto:${fsboContacts[key]}?subject=Въпросче за Имот.com&body=Здравейте%Имот.com%20`
                );
            }}
            className="footer__contacts__email"
          >
            {fsboContacts[key]}
          </div>
        </li>
      );

    if (key !== 'whats_app_number')
      return (
        <li key={index} className=" d-flex">
          <div className="mr-2">{icon}</div>
          <div className="">{fsboContacts[key]}</div>
        </li>
      );
  });

  const categories = categori.map((category) => (
    <li key={category.name}>
      {category.func ? (
        <div
          className="h6"
          onClick={() => {
            category.func();
          }}
        >
          <span className="animated"> {category.name}</span>
        </div>
      ) : (
        <h6 className="h6">
          <a href={`${category.path}`} className=" animated">
            {' '}
            {category.name}
          </a>
        </h6>
      )}
    </li>
  ));

  return (
    <footer id="footer" className="footer">
      <Container fluid="xl" className="p-3 d-flex flex-column justify-content-between mt-3  h-100">
        <Row className="mt-2">
          {aboutUs.id && (
            <Col md={6} lg={3} className="">
              <h4>{aboutUs.title}</h4>
              <div
                className="about-us"
                dangerouslySetInnerHTML={{ __html: aboutUs.description }}
              ></div>
            </Col>
          )}

          <Col md={6} lg={3} className="">
            <h4>Контакти</h4>
            <ul className="footer__contacts">{contacts}</ul>
          </Col>
          <Col md={6} lg={3} className="">
            <ul className="footer__categories mt-4">{categories}</ul>
          </Col>
          <Col md={6} lg={3} className="">
            {' '}
            <div>Изтеглете мобилното приложение от тук:</div>
            <div className="d-flex flex-column mt-2">
              <div style={{ marginLeft: '-0.7rem' }} className="mb-2">
                <a href="https://apps.apple.com/us/app/fsbo-bg/id6444754005" target="_blank">
                  <img src={ApplePay} alt="Apple pay" width="170px" height="50px" />
                </a>
              </div>
              <div className="">
                <a
                  href="http://play.google.com/store/apps/details?id=com.fsbo.reactnative"
                  target="_blank"
                >
                  <img src={GooglePlay} alt="Google play" width="170px" height="50px" />
                </a>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-3 mt-lg-0 mb-3">
          <Col xs={12} sm={6} md={5} className="d-flex flex-column"></Col>
          <Col xs={12} sm={6} md={7} className="d-flex flex-column justify-content-md-center ">
            <div className="text-center">
              <span className="">Последвайте ни в социалните мрежи:</span>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="mr-2">
                <a href="https://www.facebook.com/imot.com2024/" target="_blank">
                  <img src={Social1} alt="Facebook" width="50" height="50px" />
                </a>
              </div>
              {/* <div className="mr-2">
                <a href="/" target="_blank">
                  <img src={Social2} alt="Google play" width="50" height="50px" />
                </a>
              </div> */}
              {/* <div className="mr-2">
                <a href="/" target="_blank">
                  <img src={Social3} alt="Apple pay" width="50" height="50px" />
                </a>
              </div> */}
              <div className="mr-2">
                <a href="https://www.instagram.com/imot.com2024/" target="_blank">
                  <img src={Social4} alt="Instagram" width="50" height="50px" />
                </a>
              </div>
              <div className="mr-2">
                <a
                  href="https://www.youtube.com/channel/UClYK9_km-AOT9cWvDXO33rA/videos"
                  target="_blank"
                >
                  <img src={Youtube} alt="Youtube Icon" width="50" height="50px" />
                </a>
              </div>
            </div>
          </Col>
        </Row>

        {/* <Row className="pt-5">
          <Col xs={12} sm={6} md={5} className="">
            <span className="copyright">Copyright WP Estate. All Rights Reserved.</span>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={7}
            className="mt-2 mt-sm-0 pl-sm-0 d-flex flex-row justify-content-start justify-content-md-end policies"
          >
            <Link to={`/`} className="animated mr-3">
              Terms of use
            </Link>
            <Link to={`/`} className="animated pr-md-5">
              Privacy policy
            </Link>
          </Col>
        </Row> */}
      </Container>
    </footer>
  );
};

export default Footer;
