import React, { useEffect, useState } from 'react';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';

const FixedBox = (props) => {
  const [scrollOfTop, setScrollOfTop] = useState(0);

  useEffect(() => {
    const onPageScroll = () => {
      setScrollOfTop(window.pageYOffset);
    };
    onPageScroll();
    window.addEventListener('scroll', onPageScroll);
    return () => {
      window.removeEventListener('scroll', onPageScroll);
    };
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`box position-fixed ${scrollOfTop > 80 ? 'scrolled' : ''}`}>
      <div
        className="primary_icon mr-2"
        onClick={props.openModal}
        onKeyDown={scrollToTop}
        role="button"
        tabIndex="0"
      >
        <FaEnvelope />
      </div>
      <div
        className="primary_icon"
        onClick={scrollToTop}
        onKeyDown={scrollToTop}
        role="button"
        tabIndex="0"
      >
        <FaChevronUp />
      </div>
    </div>
  );
};

export default FixedBox;
